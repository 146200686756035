<template>
    <b-form-group>
        <b-input-group class="ontology-search-group">
            <vue-bootstrap-typeahead
                ref="typeahead"
                :id="buildTestClass('input--search-ontology')"
                class="search-ontologies"
                v-model="searchTerm"
                :data="ontologies"
                :serializer="
                    (ontology) =>
                        `${ontology.ontologyShortDisplayName} - ${ontology.ontologyLongDisplayName}`
                "
                :minMatches="minMatches"
                :maxMatches="maxMatches"
                :testId="0"
                inputClass="testid__search-ontologies--vue-bootstrap-typeahead__input"
                placeholder="SEARCH ONTOLOGIES..."
                @hit="onAutoComplete"
                @keydown.enter.native.prevent="onSearch"
                @keydown.down.native.prevent="onArrowDown"
                @keydown.up.native.prevent="onArrowUp">
                <template slot="suggestion" slot-scope="{ data, htmlText }">
                    <b-badge
                        v-html="htmlText.split(' - ')[0]"
                        class="ontology-btn inner-btn btn-primary mr-2"
                        :class="typeaheadListItemTestClass('b-badge', htmlText)"
                        :id="typeaheadListItemTestClass('b-badge', htmlText)"
                        variant="primary" />
                    <span
                        :class="typeaheadListItemTestClass('span', htmlText)"
                        :id="typeaheadListItemTestClass('span', htmlText)"
                        v-html="htmlText.split(' - ')[1]"></span>
                </template>
            </vue-bootstrap-typeahead>

            <b-input-group-append>
                <b-button
                    @click="onSearch"
                    class="search-btn testid__search-ontologies--vue-bootstrap-typeahead__button"
                    variant="primary">
                    <font-awesome-icon icon="search" />
                </b-button>
            </b-input-group-append>
        </b-input-group>
    </b-form-group>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

const ONTOLOGY_ROUTE_PREFIX = '/ontologies/';

export default {
    components: {
        VueBootstrapTypeahead,
    },
    name: 'SearchOntologies',
    data() {
        return {
            searchTerm: '',
            maxMatches: 5,
            minMatches: 2,
            foundOntologies: null,
            suggestIndex: -1,
            activeSuggestionEls: [],
        };
    },
    computed: {
        activeSuggestChild: function () {
            return this.$refs.typeahead.$refs.list.$children[this.suggestIndex];
        },
        matchedItems: function () {
            return (
                this.$refs.typeahead &&
                this.$refs.typeahead.$refs.list.matchedItems
            );
        },
    },
    mounted() {
        this.activeSuggestionEls =
            this.$refs.typeahead.$refs.list.$el.getElementsByClassName(
                'list-group-item active'
            );

        /**
         * Extends the typeahead's default handler on blur to also reset the vertical cursor (i.e. the currently active suggestion).
         * This stops the old suggestion index being used once refocused on the input.
         * */
        const oldBlurHandler = this.$refs.typeahead.handleBlur;
        this.$refs.typeahead.handleBlur = (event = {}) => {
            oldBlurHandler.call(this.$refs.typeahead, event);
            this.deactivateSuggests();
            this.suggestIndex = -1;
            this.$refs.typeahead.$refs.input.blur();
        };

        /**
         * Handle hit is called when a item has been clicked rather than entering
         */
        this.$refs.typeahead.handleHit = (ontology) => {
            if (ontology.data.loaded) {
                this.goToOntology(ontology.data.ontologyUniqueID);
            } else {
                this.onAutoComplete(ontology.data);
            }

            this.$refs.typeahead.handleBlur();
        };
    },
    methods: {
        /**
         * On search is called when the user presses enter with or without selection
         */
        onSearch() {
            // Checks if the user has selected a specific ontology
            if (this.suggestIndex > -1) {
                if (this.matchedItems[this.suggestIndex].data.loaded) {
                    this.goToOntology(
                        this.matchedItems[this.suggestIndex].data
                            .ontologyUniqueID
                    );
                } else {
                    this.onAutoComplete(
                        this.matchedItems[this.suggestIndex].data
                    );
                }
            } else {
                this.searchForTerm(this.searchTerm.toLowerCase().trim());
            }

            this.$refs.typeahead.handleBlur();
        },

        goToOntology(ontologyUniqueID) {
            const url = ONTOLOGY_ROUTE_PREFIX + ontologyUniqueID;
            this.$router.push({ path: url });
        },

        setSuggestionActive() {
            if (this.activeSuggestionEls.length) {
                this.deactivateSuggests();
            }
            if (this.suggestIndex > -1) {
                this.activeSuggestChild.$el.classList.add('active');
            }
        },

        /**
         * Loops through the suggested ontologies and deactives them
         */
        deactivateSuggests() {
            Array.from(this.activeSuggestionEls).forEach((activeEl) => {
                activeEl.classList.remove('active');
            });
        },

        onArrowUp() {
            const matchedLength = this.matchedItems.length;

            if (matchedLength) {
                this.suggestIndex = this.suggestIndex - 1;
                if (this.suggestIndex < -1) {
                    this.suggestIndex = matchedLength - 1;
                }
                this.setSuggestionActive();
            }
        },

        onArrowDown() {
            const matchedLength = this.matchedItems.length;

            if (matchedLength) {
                this.suggestIndex = this.suggestIndex + 1;
                if (this.suggestIndex >= matchedLength) {
                    this.suggestIndex = -1;
                }
                this.setSuggestionActive();
            }
        },

        /**
         * Builds the test ID if a typeahead list-item child element.
         * @param {String} componentName The name of the component for which the test ID is for.
         * @param {String} htmlText The full inner text of the element.
         * @returns {string} The element test selector.
         */
        typeaheadListItemTestClass(componentName, htmlText) {
            const ontologyId = htmlText.split(' - ')[0].toLowerCase();
            return `testid__search-ontologies--vue-bootstrap-typeahead__${componentName}--${ontologyId}`;
        },
    },
    props: {
        typeDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_TYPE_DEBOUNCE),
        },
        ontologies: {
            type: Array,
            default: () => [],
        },
        onAutoComplete: {
            type: Function,
            default: () => {},
        },
        searchForTerm: {
            type: Function,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/variables.scss';

.ontology-search-group {
    &:focus-within {
        box-shadow: rgba($primary, 0.1) 0 0.1rem 0.25rem,
            rgba($primary, 0.08) 0 0.25rem 0.75rem !important;
    }
}

.search-ontologies {
    flex: 1;

    ::v-deep input[type='search'] {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        &:focus {
            box-shadow: none !important;
        }
    }

    ::v-deep .loaded-link {
        color: #000;
    }

    ::v-deep .list-group-item {
        border-color: rgba(0, 0, 0, 0.03) !important;
    }

    ::v-deep .list-group.shadow {
        padding-top: 0;
        margin-top: 5px;
    }
}
</style>
