var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5 main-spacer"},[(!_vm.firstLoad && _vm.ontologies.length)?[_c('div',{staticClass:"ontologies-wrapper",class:_vm.ontologyContainerClass},[_c('search-ontologies',{attrs:{"ontologies":_vm.ontologies,"onAutoComplete":_vm.autoCompletedOntology,"searchForTerm":_vm.searchForTerm}}),_c('div',{staticClass:"d-flex flex-row justify-content-between mt-2 pr-1"},[_c('h5',{staticClass:"ontology-count text-muted"},[_c('small',[_c('strong',[_vm._v(_vm._s(_vm.total))]),_vm._v(" "+_vm._s(_vm.total !== 1 ? 'ontologies' : 'ontology'))])]),(_vm.numberPages > 1)?_c('pagination',{attrs:{"isLoading":_vm.isLoading,"current-page":_vm.currentPage,"number-pages":_vm.numberPages}}):_vm._e(),_c('div',{staticClass:"icons-container"},[_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                            boundary: 'window',
                            title: 'Toggle the unloaded ontology visibility',
                            delay: { show: _vm.showDelay, hide: 0 },
                        }),expression:"{\n                            boundary: 'window',\n                            title: 'Toggle the unloaded ontology visibility',\n                            delay: { show: showDelay, hide: 0 },\n                        }",modifiers:{"hover":true}}],staticClass:"icon-button",attrs:{"id":_vm.buildTestClass('button--toggle-unloaded'),"icon":_vm.showUnloaded ? 'eye' : 'eye-slash',"size":"lg"},on:{"click":_vm.onHideToggleClick}}),_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                            boundary: 'window',
                            title: 'Toggles between grid and list view',
                            delay: { show: _vm.showDelay, hide: 0 },
                        }),expression:"{\n                            boundary: 'window',\n                            title: 'Toggles between grid and list view',\n                            delay: { show: showDelay, hide: 0 },\n                        }",modifiers:{"hover":true}}],staticClass:"icon-button",attrs:{"icon":_vm.gridView ? 'list' : 'border-all',"size":"lg"},on:{"click":_vm.onGridToggleClick}})],1)],1),_c('div',{staticClass:"onto-items-wrapper",class:_vm.ontoItemsWrapper},_vm._l((_vm.currentPageOntologies),function(item){return _c('div',{key:item.ontologyUniqueID,class:_vm.wrapperClass + ' onto-item-wrapper',attrs:{"id":_vm.buildTestClass(
                            'div--onto-item-wrapper' + item.ontologyUniqueID
                        )}},[_c('onto-item',_vm._b({staticClass:"p-2 shadow-sm rounded h-100",attrs:{"maxDescLength":200,"isEditButton":!_vm.$store.getters.isAdmin,"disabled":!item.loaded ||
                            _vm.isOntoLoading(item.ontologyUniqueID),"testId":item.ontologyUniqueID},on:{"onto:load":function($event){return _vm.onLoadOntology($event, item)},"onto:edit":function($event){return _vm.$router.push({
                                name: 'class',
                                params: {
                                    ontologyID: item.ontologyUniqueID,
                                    isAutoEdit: true,
                                    initialData: item,
                                },
                            })}}},'onto-item',item,false,true))],1)}),0),(_vm.total > 0)?_c('div',{staticClass:"d-flex flex-row justify-content-between mt-2 pr-1"},[_c('h5',{staticClass:"ontology-count text-muted"},[_c('small',[_c('strong',[_vm._v(_vm._s(_vm.total))]),_vm._v(" "+_vm._s(_vm.total !== 1 ? 'ontologies' : 'ontology'))])]),(_vm.numberPages > 1)?_c('pagination',{attrs:{"isLoading":_vm.isLoading,"current-page":_vm.currentPage,"number-pages":_vm.numberPages}}):_vm._e(),_c('div',{staticClass:"icons-container"},[(_vm.total > 0)?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                            boundary: 'window',
                            title: 'Toggle the unloaded ontology visibility',
                            delay: { show: _vm.showDelay, hide: 0 },
                        }),expression:"{\n                            boundary: 'window',\n                            title: 'Toggle the unloaded ontology visibility',\n                            delay: { show: showDelay, hide: 0 },\n                        }",modifiers:{"hover":true}}],staticClass:"icon-button",attrs:{"icon":_vm.showUnloaded ? 'eye' : 'eye-slash',"size":"lg"},on:{"click":_vm.onHideToggleClick}}):_vm._e(),_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                            boundary: 'window',
                            title: 'Toggles between grid and list view',
                            delay: { show: _vm.showDelay, hide: 0 },
                        }),expression:"{\n                            boundary: 'window',\n                            title: 'Toggles between grid and list view',\n                            delay: { show: showDelay, hide: 0 },\n                        }",modifiers:{"hover":true}}],staticClass:"icon-button",attrs:{"icon":_vm.gridView ? 'list' : 'border-all',"size":"lg"},on:{"click":_vm.onGridToggleClick}})],1)],1):_vm._e()],1)]:_vm._e(),(!_vm.firstLoad && !_vm.total)?_c('div',{staticClass:"not-found"},[_c('h4',{staticClass:"text-secondary d-flex align-items-center"},[_c('font-awesome-icon',{attrs:{"icon":"info-circle","size":"2x"}}),(!_vm.searched)?_c('span',{staticClass:"pl-3"},[_vm._v(" Sorry, no ontology could be retrieved. ")]):_c('span',{staticClass:"pl-3"},[_vm._v(" Sorry, no ontology could be found with your search term. ")])],1),(!_vm.searched)?_c('h5',[_vm._v(" Check you are online. If so, there might have been a problem during setup. ")]):_c('h5',[_vm._v(" Please check you have typed in the search term correctly. ")]),_c('p',[_vm._v(" Refer to our "),_c('b-link',{attrs:{"target":"_blank","href":_vm.helpUrl}},[_vm._v("knowledge base")]),_vm._v(" for relevant help or "),_c('b-link',{attrs:{"rel":"external","href":_vm.helpEmail}},[_vm._v("contact us")]),_vm._v(". ")],1)]):_vm._e(),(_vm.firstLoad)?_c('div',{staticClass:"loading centered"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }