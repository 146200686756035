<template>
    <div class="pb-5 main-spacer">
        <template v-if="!firstLoad && ontologies.length">
            <div class="ontologies-wrapper" :class="ontologyContainerClass">
                <search-ontologies
                    :ontologies="ontologies"
                    :onAutoComplete="autoCompletedOntology"
                    :searchForTerm="searchForTerm" />

                <div class="d-flex flex-row justify-content-between mt-2 pr-1">
                    <h5 class="ontology-count text-muted">
                        <small
                            ><strong>{{ total }}</strong>
                            {{ total !== 1 ? 'ontologies' : 'ontology' }}</small
                        >
                    </h5>
                    <pagination
                        v-if="numberPages > 1"
                        :isLoading="isLoading"
                        :current-page="currentPage"
                        :number-pages="numberPages" />
                    <div class="icons-container">
                        <font-awesome-icon
                            class="icon-button"
                            :id="buildTestClass('button--toggle-unloaded')"
                            @click="onHideToggleClick"
                            :icon="showUnloaded ? 'eye' : 'eye-slash'"
                            size="lg"
                            v-b-tooltip.hover="{
                                boundary: 'window',
                                title: 'Toggle the unloaded ontology visibility',
                                delay: { show: showDelay, hide: 0 },
                            }" />
                        <font-awesome-icon
                            class="icon-button"
                            @click="onGridToggleClick"
                            :icon="gridView ? 'list' : 'border-all'"
                            size="lg"
                            v-b-tooltip.hover="{
                                boundary: 'window',
                                title: 'Toggles between grid and list view',
                                delay: { show: showDelay, hide: 0 },
                            }" />
                    </div>
                </div>
                <div class="onto-items-wrapper" :class="ontoItemsWrapper">
                    <div
                        v-for="item in currentPageOntologies"
                        :class="wrapperClass + ' onto-item-wrapper'"
                        :key="item.ontologyUniqueID"
                        :id="
                            buildTestClass(
                                'div--onto-item-wrapper' + item.ontologyUniqueID
                            )
                        ">
                        <onto-item
                            class="p-2 shadow-sm rounded h-100"
                            v-bind.sync="item"
                            :maxDescLength="200"
                            :isEditButton="!$store.getters.isAdmin"
                            :disabled="
                                !item.loaded ||
                                isOntoLoading(item.ontologyUniqueID)
                            "
                            :testId="item.ontologyUniqueID"
                            @onto:load="onLoadOntology($event, item)"
                            @onto:edit="
                                $router.push({
                                    name: 'class',
                                    params: {
                                        ontologyID: item.ontologyUniqueID,
                                        isAutoEdit: true,
                                        initialData: item,
                                    },
                                })
                            " />
                    </div>
                </div>
                <div
                    class="d-flex flex-row justify-content-between mt-2 pr-1"
                    v-if="total > 0">
                    <h5 class="ontology-count text-muted">
                        <small
                            ><strong>{{ total }}</strong>
                            {{ total !== 1 ? 'ontologies' : 'ontology' }}</small
                        >
                    </h5>
                    <pagination
                        v-if="numberPages > 1"
                        :isLoading="isLoading"
                        :current-page="currentPage"
                        :number-pages="numberPages" />
                    <div class="icons-container">
                        <font-awesome-icon
                            v-if="total > 0"
                            class="icon-button"
                            @click="onHideToggleClick"
                            :icon="showUnloaded ? 'eye' : 'eye-slash'"
                            size="lg"
                            v-b-tooltip.hover="{
                                boundary: 'window',
                                title: 'Toggle the unloaded ontology visibility',
                                delay: { show: showDelay, hide: 0 },
                            }" />
                        <font-awesome-icon
                            class="icon-button"
                            @click="onGridToggleClick"
                            :icon="gridView ? 'list' : 'border-all'"
                            size="lg"
                            v-b-tooltip.hover="{
                                boundary: 'window',
                                title: 'Toggles between grid and list view',
                                delay: { show: showDelay, hide: 0 },
                            }" />
                    </div>
                </div>
            </div>
        </template>

        <div class="not-found" v-if="!firstLoad && !total">
            <h4 class="text-secondary d-flex align-items-center">
                <font-awesome-icon icon="info-circle" size="2x" />
                <span v-if="!searched" class="pl-3">
                    Sorry, no ontology could be retrieved.
                </span>
                <span v-else class="pl-3">
                    Sorry, no ontology could be found with your search term.
                </span>
            </h4>
            <h5 v-if="!searched">
                Check you are online. If so, there might have been a problem
                during setup.
            </h5>
            <h5 v-else>
                Please check you have typed in the search term correctly.
            </h5>
            <p>
                Refer to our
                <b-link target="_blank" :href="helpUrl">knowledge base</b-link>
                for relevant help or
                <b-link rel="external" :href="helpEmail">contact us</b-link>.
            </p>
        </div>

        <div class="loading centered" v-if="firstLoad"></div>
    </div>
</template>

<script>
import OntoItem from '@/components/OntoList/OntoItemOld';
import Pagination from '@/components/ui/Pagination';
import SearchOntologies from '@/components/ui/SearchOntologies';
import ApiOntology from '@/api/ontology';

export default {
    name: 'OntoList',
    components: {
        OntoItem,
        Pagination,
        SearchOntologies,
    },

    props: {
        pageSize: {
            type: Number,
            default: 21,
        },

        helpUrl: {
            type: String,
            default: process.env.VUE_APP_HELP_URL,
        },

        helpEmail: {
            type: String,
            default: process.env.VUE_APP_HELP_EMAIL,
        },
        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },
    },

    data() {
        let lastSearchedTerm = '';

        return {
            ontologies: [],
            loadedOntologies: [],
            displayedOntologies: [],
            from: 0,
            to: 0,
            currentPage: 1,
            firstLoad: true,
            isLoading: true,
            searched: false,
            gridView: false,
            showUnloaded: true,
            ontologyContainerClass: '',
            ontoItemsWrapper: '',
            wrapperClass: '',
            lastSearchedTerm: '',
        };
    },

    computed: {
        numberPages: function () {
            return Math.ceil(this.total / this.pageSize);
        },
        currentPageOntologies: function () {
            if (this.total === 0) return;
            if (this.showUnloaded) {
                return this.displayedOntologies.slice(this.from, this.to);
            }
            return this.displayedOntologies.slice(this.from, this.to);
        },
        total: function () {
            return this.displayedOntologies.length;
        },
        activeOntologies: function () {
            return this.showUnloaded ? this.ontologies : this.loadedOntologies;
        },
    },

    watch: {
        $route(to, from) {
            this.onRouteParam(to.params);
        },

        ontologies: function () {
            window.scrollTo(0, 0);
        },
    },

    created() {
        this.fetchOntologies();
    },
    mounted() {
        this.gridView = JSON.parse(window.localStorage.getItem('gridView'));
        this.toggleGridView();
    },
    methods: {
        /**
         * Changes the loading state.
         */
        setLoading(isLoading) {
            this.isLoading = isLoading;
        },
        fetchOntologies() {
            this.firstLoad = true;
            this.setLoading(true);

            ApiOntology.getAll('all')
                .then((response) => {
                    let res = (response.data = response.data.map((ontology) => {
                        ontology.ontologyMetadataJSONBean.loaded =
                            ontology.loaded || false;
                        return ontology.ontologyMetadataJSONBean;
                    }));

                    this.ontologies = res;
                    this.displayedOntologies = res;
                })
                .finally(() => {
                    this.firstLoad = false;
                    this.setLoading(false);
                    this.onRouteParam(this.$route.params);
                    this.loadedOntologies = this.ontologies.filter(
                        (ontology) => ontology.loaded
                    );
                });
        },

        autoCompletedOntology(ontology) {
            if (ontology) {
                this.displayedOntologies = [ontology];
            }

            this.changePageNumber(1);
            this.searched = true;
        },

        /**
         * Searches for a term that is passed from the Search Ontologies component
         * @param {string} searchTerm - Used to search the properties of the ontology list
         */
        searchForTerm(searchedTerm) {
            this.loading = true;
            this.searched = true;
            this.showUnloaded = true;
            this.lastSearchedTerm = searchedTerm;

            if (searchedTerm === '') {
                this.displayedOntologies = this.activeOntologies;
            } else {
                searchedTerm = searchedTerm.toLowerCase();
                this.displayedOntologies = this.activeOntologies.filter(
                    (ontology) => {
                        if (
                            ontology.ontologyShortDisplayName
                                .toLowerCase()
                                .match(searchedTerm) ||
                            ontology.ontologyLongDisplayName
                                .toLowerCase()
                                .match(searchedTerm.toLowerCase())
                        ) {
                            return ontology;
                        }
                    }
                );
            }

            this.changePageNumber(1);
            if (this.$route.path != '/ontologies/page/1') {
                this.$router.push({
                    name: this.$route.name,
                    params: Object.assign({}, this.$route.params, {
                        number: this.currentPage,
                    }),
                });
            }
            this.loading = false;
        },

        /**
         * Used to keep track of the ontology array position for the page numbers
         * @param {number} currentPage - Current page index used for checking what ontologies to be loaded
         */
        changePageNumber(currentPage) {
            if (currentPage >= this.total / this.pageSize)
                currentPage = Math.ceil(this.total / this.pageSize);

            if (currentPage > 0) {
                this.from = (currentPage - 1) * this.pageSize;
                this.to =
                    this.from + this.pageSize > this.total
                        ? this.total
                        : this.from + this.pageSize;
            }

            this.currentPage = currentPage;
        },

        onRouteParam(params) {
            const pageNum = parseInt(params.number || '1');
            this.changePageNumber(pageNum);
        },

        onLoadOntology(ontologyID, ontoItem) {
            this.$store.dispatch('loadOntoStart', ontoItem);
            ApiOntology.load({ ontologyID });
        },

        /**
         * Determines if a given ontology is in the app-wide list of ontologies pending completion of the loading process.
         * @param {string} ontologyUniqueID - ID for the ontology whose loading status is to be determined.
         */
        isOntoLoading(ontologyUniqueID) {
            return (
                this.$store.getters.loadingOntoIDs.indexOf(ontologyUniqueID) !==
                -1
            );
        },
        onHideToggleClick() {
            this.showUnloaded = !this.showUnloaded;
            this.toggleHideUnloaded();
        },
        onGridToggleClick() {
            this.gridView = !this.gridView;
            window.localStorage.setItem('gridView', this.gridView);
            this.toggleGridView();
        },
        toggleHideUnloaded() {
            if (!this.showUnloaded) {
                this.displayedOntologies = this.displayedOntologies.filter(
                    (ontology) => {
                        if (ontology.loaded) return ontology;
                    }
                );
            } else {
                this.searchForTerm(this.lastSearchedTerm);
            }

            this.changePageNumber(1);
            if (this.$route.path != '/ontologies/page/1') {
                this.$router.push({
                    name: this.$route.name,
                    params: Object.assign({}, this.$route.params, {
                        number: this.currentPage,
                    }),
                });
            }
        },
        toggleGridView() {
            if (this.gridView) {
                this.ontologyContainerClass = 'grid-view';
                this.ontoItemsWrapper = 'd-flex flex-wrap';
                this.wrapperClass = 'p-2 col-xs-12 col-lg-6 col-xl-4';
            } else {
                this.ontologyContainerClass = '';
                this.ontoItemsWrapper = '';
                this.wrapperClass = '';
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/variables.scss';

.ontology-count {
    display: block;
    margin-bottom: 0.7rem;
    font-weight: 500;
}

.onto-item-wrapper {
    margin-bottom: 1.5em;
}

.icon-button {
    cursor: pointer;
    color: #9b9b9b;
    margin-right: 10px;
}

.ontologies-wrapper {
    margin: 0 auto;
    padding: 0 2em;
}

.ontologies-wrapper {
    max-width: 1920px !important;
}

::v-deep .item-heading {
    .title-container.disabled {
        pointer-events: none;
    }

    .title-name {
        margin: 0 0.5rem;
    }
}

::v-deep .tree-edit-btn {
    font-size: 0.825rem !important;
}

::v-deep .item-size {
    font-size: 90%;
    color: $text-muted;
}

.not-found {
    min-width: 300px;
    max-width: 500px;
    width: 60%;
    margin: 0 auto;

    h4 {
        margin: 1rem auto 2rem;
        font-weight: 400;
    }

    a {
        font-weight: 500;
    }

    @media (min-height: 600px) {
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
