<template>
    <div class="ontology-item clearfix d-flex flex-column">
        <div class="item-info" :class="{ 'not-loaded': !fullyLoaded }">
            <div
                class="item-heading h5 mb-0 d-flex align-items-center justify-content-between">
                <component
                    :is="isTitleUnclickable ? 'span' : 'b-link'"
                    class="title-container d-flex align-items-center"
                    :to="{
                        name: 'ontology',
                        params: {
                            ontologyID: ontologyUniqueID,
                            initialData: fullAttrs,
                        },
                    }"
                    :disabled="!fullyLoaded">
                    <b-badge
                        v-if="isOntoBadge"
                        class="parent-btn"
                        variant="primary"
                        :class="this.buildTestClass('b-badge')">
                        {{ ontologyShortDisplayName }}
                    </b-badge>
                    <strong
                        class="title-name"
                        :class="buildTestClass('ontology-name')"
                        >{{ ontologyLongDisplayName }}</strong
                    >
                </component>
                <tree-edit-button
                    test-id="0"
                    v-if="fullyLoaded && isEditButton"
                    :ontologyID="ontologyUniqueID"
                    @click.native.prevent.stop="$emit('onto:edit')" />
            </div>
            <div v-if="isClassCount && fullyLoaded" class="item-size">
                <strong>Size</strong>: {{ classCount }} classes and
                {{ propertyCount }} properties in total
            </div>
            <div>
                <editable-text
                    v-if="description !== 'Test'"
                    test-id="0"
                    :text="description"
                    :maxLength="maxDescLength" />
            </div>

            <template v-if="fullyLoaded">
                <b-btn
                    class="properties-btn border-0 p-0"
                    size="sm"
                    variant="link-secondary"
                    :class="{ expanded: isShowProperties }"
                    @click.prevent.stop="isShowProperties = !isShowProperties">
                    <font-awesome-icon icon="chevron-circle-right" />
                    {{ isShowProperties ? 'Hide' : 'Show' }} properties
                </b-btn>

                <b-collapse
                    class="container"
                    v-model="isShowProperties"
                    :id="`onto-${ontologyUniqueID}`">
                    <div class="row my-2">
                        <dl
                            v-for="(propertyList, listIndex) in propertyBlocks"
                            class="col-md-6 mb-0"
                            :key="`onto-${ontologyUniqueID}-${propertyList}`">
                            <div
                                v-for="(propertyName, index) in propertyList"
                                :class="buildTestClass(index.toString())"
                                :key="`onto-${ontologyUniqueID}-${propertyName}`">
                                <dt class="onto-property-name">
                                    {{
                                        upperFirst(humanReadable(propertyName))
                                    }}
                                </dt>
                                <dd
                                    class="onto-property-value word-break mb-3"
                                    :class="{
                                        'text-muted': isEmptyProp(propertyName),
                                        'is-property': isUrl(
                                            ontologyProp(propertyName)
                                        ),
                                    }"
                                    @click="
                                        onClickProperty(
                                            ontologyProp(propertyName)
                                        )
                                    ">
                                    <span ref="ontoProp">{{
                                        ontologyProp(propertyName)
                                    }}</span>
                                    <text-actions
                                        v-if="!isEmptyProp(propertyName)"
                                        :copyTargetName="
                                            $pluralize.singular(
                                                humanReadable(propertyName)
                                            ) + ' URL'
                                        "
                                        :copyTargetEl="
                                            getTarget(
                                                propertyBlocks[listIndex]
                                                    .length *
                                                    listIndex +
                                                    index,
                                                listIndex
                                            )
                                        "
                                        :url="
                                            propertyName ===
                                            'ontologyFileWebLocation'
                                                ? ontologyProp(propertyName)
                                                : ''
                                        "
                                        :isDownload="true"
                                        :isSearchActions="false" />
                                </dd>
                            </div>
                        </dl>
                    </div>
                </b-collapse>
            </template>
        </div>
        <div v-if="!fullyLoaded" class="mt-auto d-flex pt-2">
            <div class="mr-auto">
                <span v-show="!loading" class="load-status text-muted">
                    <font-awesome-icon icon="minus-circle" />
                    Not loaded
                </span>
                <span class="loading align-middle ml-2" v-show="loading"></span>
            </div>
            <div>
                <b-button
                    v-if="$store.getters.isEditor(ontologyUniqueID)"
                    variant="secondary"
                    size="sm"
                    class="load-btn"
                    :id="this.buildTestClass('btn--load-ontology')"
                    v-b-tooltip.hover="{
                        boundary: 'window',
                        title: 'Loads the latest version of this ontology',
                        delay: { show: showDelay, hide: 0 },
                    }"
                    :disabled="loading"
                    @click.prevent.stop="onLoadOnto">
                    Load
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce, find, pull, sortBy } from 'lodash';

import EditableText from '@/components/ui/EditableText';
import TreeEditButton from '@/components/ui/TreeEditButton';
import TextActions from '@/components/ui/TextActions';
import ApiOntology from '@/api/ontology';
import router from '@/router';
import { isValidUrl } from '@/utils';

export default {
    name: 'OntoItem',
    components: {
        EditableText,
        TreeEditButton,
        TextActions,
    },
    props: {
        nestedPropNames: {
            type: Array,
            default: function () {
                return [
                    process.env.VUE_APP_ANNOTATION_WRAPPER,
                    process.env.VUE_APP_RELATIONAL_WRAPPER,
                ];
            },
        },

        ontologyShortDisplayName: {
            type: String,
            default: '',
        },

        ontologyLongDisplayName: {
            type: String,
            default: '',
        },

        ontologyUniqueID: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        maxDescLength: {
            type: Number,
            default: 999999,
        },

        isOntoBadge: {
            type: Boolean,
            default: true,
        },

        isTitleUnclickable: {
            type: Boolean,
            default: false,
        },

        isEditButton: {
            type: Boolean,
            default: true,
        },

        isClassCount: {
            type: Boolean,
            default: true,
        },

        loaded: {
            type: Boolean,
            default: true,
        },

        typeDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_TYPE_DEBOUNCE),
        },

        showDelay: {
            type: Number,
            default: parseInt(process.env.VUE_APP_SHOW_DELAY),
        },

        isPropertiesShown: {
            type: Boolean,
            default: false,
        },
    },

    data: function () {
        return {
            classCount: 0,
            propertyCount: 0,

            // True if the collapsible list of properties is revealed
            isShowProperties: this.isPropertiesShown,

            // Lists of properties, two-column layout (left and right)
            leftPropNames: [],
            rightPropNames: [],
            additionalProps: ['keywords', 'ontologyUniqueID', 'version'],
        };
    },

    computed: {
        // Complements the ontology data passed in with the class count.
        // NOTE: By default, named props are excluded from the list of attributes.
        fullAttrs: function () {
            return Object.assign(
                { classCount: this.classCount },
                this.$options.propsData,
                this.$attrs
            );
        },

        // Determines if a given ontology is still being loaded
        loading: function () {
            return (
                this.$store.getters.loadingOntoIDs.indexOf(
                    this.ontologyUniqueID
                ) !== -1
            );
        },

        // Checks that an ontology is loaded from the point of view of the browser.
        // NOTE: the server may flag an ontology as loaded before the load request finishes.
        fullyLoaded: function () {
            return this.loaded && !this.loading;
        },

        /**
         * Flatten block of properties, adds locally defined props names, sorts and chunk in envenly blocks.
         * @returns {Array<[Array<String>, Array<String>]>}
         */
        propertyBlocks: function () {
            const allProperties = [
                ...this.leftPropNames,
                ...this.rightPropNames,
                ...this.additionalProps,
            ].sort();

            return this.chunkArray(allProperties);
        },
    },

    watch: {
        '$store.getters.ontoIndex'() {
            if (
                this.$store.getters.hasOnto(this.ontologyUniqueID) &&
                !this.loaded
            ) {
                this.$emit('update:loaded', true);
            } else if (
                !this.$store.getters.hasOnto(this.ontologyUniqueID) &&
                this.loaded
            ) {
                this.$emit('update:loaded', false);
            }
        },

        ontologyUniqueID: {
            handler: 'onOntologyID',
            immediate: true,
        },

        fullyLoaded(isLoaded) {
            if (isLoaded) {
                this.fetchCounts();
            }
        },
    },

    created() {
        this.onLoadOnto = debounce(this.onLoadOnto, this.typeDelay);
        this.keywords = this.fullAttrs.keywords || [];
    },

    methods: {
        onOntologyID() {
            this.buildPropLists();
            this.fetchCounts();
        },

        fetchCounts() {
            if (
                this.isClassCount &&
                this.fullyLoaded &&
                this.ontologyUniqueID
            ) {
                ApiOntology.ontologyCounts({
                    ontologyID: this.ontologyUniqueID,
                }).then((counts) => {
                    this.propertyCount = counts.properties;
                    this.classCount = counts.classes;

                    this.$emit('onto:size', counts.classes);
                });
            }
        },

        /**
         * Dynamically extracts and sorts the names of the ontology's data object keys concerned with properties (e.g. partonomy, derives...).
         * It also splits the properties into two groups of similar size.
         */
        buildPropLists() {
            this.rightPropNames = sortBy(
                Object.keys(this.$attrs).filter((keyName) => {
                    return /properties|property|location/i.test(keyName);
                })
            );

            this.nestedPropNames.forEach((propName) => {
                if (this.$attrs.hasOwnProperty(propName)) {
                    this.$attrs[propName].forEach((definitionObj) => {
                        this.rightPropNames.push(
                            propName + '.' + definitionObj.name
                        );
                    });
                    pull(this.rightPropNames, propName);
                }
            });

            this.leftPropNames = this.rightPropNames.splice(
                0,
                Math.floor(this.rightPropNames.length / 2)
            );
        },

        /**
         * Normalises ontology property values to some human-readable description.
         * @param {string} propertyName - Name of the property to process.
         */
        ontologyProp(propertyName) {
            const customPropMatch = this.isCustomProperty(propertyName);
            let propertyValue = this.$attrs[propertyName];
            let output = '';

            if (this.fullAttrs[propertyName]) {
                propertyValue = this.fullAttrs[propertyName];
                // If a nested property, fetches the value from within the defitinion object.
            } else if (customPropMatch) {
                propertyValue = find(this.$attrs[customPropMatch[0]], {
                    name: customPropMatch[1],
                }).iri;
            }

            // If there is a list of values and they are URIs, serialises it.
            if (Array.isArray(propertyValue)) {
                output = propertyValue
                    .map((value) => {
                        if (
                            value.hasOwnProperty(
                                'obsoleteAnnotationPropertyIRI'
                            )
                        ) {
                            return value.obsoleteAnnotationPropertyIRI;
                        } else if (
                            value.values &&
                            Array.isArray(value.values)
                        ) {
                            return value.values.join(', ');
                        } else {
                            if (propertyName === 'customAnnotationProperties') {
                                return value.name;
                            }
                            return value;
                        }
                    })
                    .join(', ');

                // It's a primitive value.
            } else {
                output = propertyValue;
            }

            // Prints out "None" if that ontology property is not even present or its value is an empty string.
            if (output && output.length) {
                return output;
            } else {
                return 'None';
            }
        },

        /**
         * Determines if the value of a given ontology property is empty, ie empty array, string or non-existent at all.
         * @parama {string} propertyName - Name of the ontology property to be checked.
         */
        isEmptyProp(propertyName) {
            const propValue = this.ontologyProp(propertyName);
            return !propValue.length || propValue === 'None';
        },

        onClickProperty(url) {
            if (isValidUrl(url)) {
                router.push({
                    name: 'property',
                    params: {
                        ontologyID: this.ontologyUniqueID,
                        primaryID: url,
                    },
                });
            }
        },

        onLoadOnto() {
            this.$emit('onto:load', this.ontologyUniqueID);
        },

        getTarget(index, li) {
            if (li > 0) index += li - 1;
            const comp = this;

            return () => {
                return comp.$refs.ontoProp[index];
            };
        },

        /**
         * Splits an arrau evenly  in two chunks.
         * @param {Array} arr
         * @returns {*[]}
         */
        chunkArray(arr) {
            const floor = Math.floor(arr.length / 2);
            const ceil = arr.length - floor;
            const secondHalf = arr.splice(ceil, floor);

            return [arr, secondHalf];
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../scss/variables';
.item-info {
    opacity: 1;
    transition: opacity 0.3s ease;

    .title-name {
        line-height: 1.5;
    }

    &.not-loaded {
        opacity: 0.5;

        .title-name {
            color: $secondary;
        }

        ::v-deep .toggle-text {
            display: none;
        }
    }
}

.parent-btn {
    border: 1px solid $primary;
    transition: background 0.3s ease;

    .not-loaded & {
        border: 1px solid $text-muted;
        background: transparent;
    }
}

.loading {
    position: relative;
    display: inline-block;
    font-size: 1em;

    &:after {
        content: 'Importing ontology in the background...';
        font-weight: inherit;
        color: inherit;
    }
}

.load-btn {
    padding: 0.15rem 0.5rem;
}

.properties-btn {
    text-decoration: none !important;
    color: $secondary;

    &:not(.expanded) {
        opacity: 0.8;

        &:hover {
            color: $secondary;
        }
    }

    &.expanded {
        color: $link-hover-color;
    }

    &:hover {
        opacity: 1;
    }
}

.fa-chevron-circle-right {
    transition: $modal-transition;

    .expanded & {
        transform: rotate(90deg);
    }
}

.file-link.disabled:hover {
    text-decoration: none;
    color: $secondary !important;
}

.onto-property-name,
.onto-property-value {
    padding: 0 0.25rem;
    background: darken($white, 2%);
}

.onto-property-value {
    margin-bottom: 1.2rem !important;

    .text-actions {
        opacity: 0.3;
    }

    &:hover .text-actions {
        opacity: 1;
    }
}

.is-property {
    color: $property;

    &:hover {
        cursor: pointer;
        color: $url-color;
    }
}
</style>
